.alumni-members{
    width: 100%;
    padding: 70px 0 !important;
}

.alumni-members .card-section{
    padding: 20px 0 !important;
}

.alumni-members .section-heading{
    padding: 10px 0;
    text-align: center;
}

.alumni-members .section-heading h6{
    font-size: 14px;
    font-weight: 700;
    color: #001D6C !important;
    text-transform: uppercase;
}

.alumni-members .section-heading p{
    font-weight: 700px;
    font-size: 30px;
    padding: 0 10%;
}

.alumni-members .profile-card img{
    width: 100%;
}

.alumni-members .profile-card h6{
    margin: 15px 0 5px 0;
}

.alumni-members .profile-card p{
    margin: 5px 0 5px 0;
}

.alumni-members .profile-card span{
    margin: 0px 0 15px 0;
    font-size: 15px;
    color: #686868;
}
.alumni-members .profile-card{
    text-align: center;
    border: 1px solid #f1f0f0;
    padding-bottom: 15px;
}

@media screen and (max-width: 912px) {
    .alumni-members .profile-card{
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 540px) {
    .alumni-members{
        padding: 70px 0 10px 0 !important;
    }

    .alumni-members .section-heading p{
        padding: 0;
    }
}