.services-section{
    width: 100%;
    padding: 70px 0 !important;
    background-color: #F7F9FC;
}

.services-section .services-card-section{
    padding: 20px 0 !important;
}

.services-section .section-heading{
    padding: 10px 0;
    text-align: center;
}

.services-section .section-heading h6{
    font-size: 14px;
    font-weight: 700;
    color: #001D6C !important;
}

.services-section .section-heading p{
    font-weight: 700px;
    font-size: 30px;
    padding: 0 10%;
}

.services-section .services-card-section p{
    margin: 5px 50px 15px 50px;
}

.services-section .services-card-section{
    text-align: center;
}

.services-section .services-card-section svg{
    font-size: 50px;
    color: #9e9d9d;
}

.services-section .services-card-section .service-card{
    padding: 30px 50px;
}


@media screen and (max-width: 820px) {
    .services-section .section-heading p{
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .services-section .services-card-section p{
        margin: 5px 0px 15px 0px;
    }

    .services-section .services-card-section .service-card{
        padding: 30px 10px;
    }
}