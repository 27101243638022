.perks{
    width: 100%;
    background-color: #F7F9FC;
    padding: 70px 0;
}

.perks .section-heading{
    text-align: center;
}

.perks .section-heading h6{
    font-size: 14px;
    font-weight: 700;
    color: #001D6C !important;
}

.perks .section-heading h1{
    margin: 5px 50px 15px 50px;
}

.perks .section-heading p{
    margin: 5px 150px 15px 150px;
}

.perks .cards-section{
    padding: 50px 0;
}

.perks .cards-section .card{
    width: 19rem !important;
    transition: transform 0.3s ease;
}

.perks .cards-section .card:hover{
    transform: translateY(-10px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.perks .cards-section .card .card-title{
    font-weight: 700;
    margin-bottom: 20px;
}

.perks .cards-section .card .head-title{
    font-size: 13px;
    font-weight: 500;
}

.perks .cards-section .card .card-text{
    min-height: 55px;
}

.perks .cards-section .card .card-body .value-text{
    font-size: 13px;
    text-align: right;
    font-weight: 500;
    min-height: 30px !important;
}

@media screen and (max-width: 912px) {
    .perks .cards-section .card{
        margin-bottom: 40px;
    }

    .perks{
        padding: 30px 0;
    }

    .perks .section-heading p{
        margin: 5px 15px 15px 15px;
    }
    
    .perks .section-heading h1{
        margin: 5px 0px 15px 0px;
    }
}

@media screen and (max-width: 540px) {
    .perks{
        padding: 30px 0;
    }
}