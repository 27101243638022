.events-section{    
    width: 100%;
    padding: 50px 0 !important;
}

.events-section .section-heading{
    padding: 10px 0;
    text-align: center;
}

.events-section .section-heading h6{
    font-size: 14px;
    font-weight: 700;
    color: #001D6C !important;
}

.events-section .section-heading h2{
    font-weight: 700px;
    font-size: 38px;
    margin: 15px 10% 30px 10%;
}

.events-section .section-heading p{
    margin: 20px 50px;
}

.events-section .card{
    margin: 10px 10px;
    width: 21rem !important;
}

.events-section .carousel .carousel-control-prev .carousel-control-prev-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
}

.events-section .carousel .carousel-control-next .carousel-control-next-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.events-section .card .card-body .card-subtitle{
    margin: 15px 0;
    color: #001D6C;
}

.events-section .card .card-body .card-title{
    min-height: 75px;
}

.events-section .card .card-body .card-text{
    min-height: 50px;
}

@media screen and (max-width: 912px) {
    .events-section .card{
        margin: 10px 10px;
        width: 17rem !important;
    }
}

@media screen and (max-width: 540px) {
    .events-section .section-heading h2{
        margin: 15px 5px 30px 5px;
    }
    
    .events-section .section-heading p{
        margin: 20px 5px;
    } 

    .events-section .card{
        margin: 10px 10px;
        width: 19rem !important;
    }
}