.header{
    width: 100%;
    padding: 10px 0;
    background-color: transparent;
    z-index: 1;
}

.navbar-background-color-active{
    background-color: #FFFFFF !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e3e1e1;
}

.header .nav a{
    margin: 0 15px;
    font-size: 17px !important;
    font-weight: 600;
    color: #FFFFFF !important;
}

.header .nav a:hover{
    color: #271c6b !important;
    font-weight: 600;
}

.navbar-background-color-active .nav a{
    color: #000000 !important;
}

.header .signin-btn{
    display: flex;
    justify-content: center;
}

.header .signin-btn span{
    margin: 0 5px;
}

.header .signin-btn span svg{
    width: 10px;
}

.header .signin-btn .btn-primary{
    border-radius: 0 !important;
}

.header img{
    width: 120px;
    height: 35px;
}

@media screen and (max-width: 575px) {
    .header{
        background-color: #FFFFFF !important;
    }

    .header .nav a{
        color: #000000 !important;
    }

    .offcanvas.show .signin-btn .btn-primary{
        border-radius: 0 !important;
        margin-top: 15px;
    }

    .offcanvas.show .signin-btn span{
        margin: 0 5px;
    }
    
    .offcanvas.show .signin-btn span svg{
        width: 10px;
    }
}
