footer{
    width: 100%;
    padding: 25px 0 15px 0;
    background-color: #CBD2E0;
}

footer .footer-head img{
    width: 75px;
    height: 35px;
}

footer .footer-bottom{
    text-align: center;
}

footer .footer-bottom p{
    margin: 0;
}