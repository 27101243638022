.hero-section{
    width: 100%;
    padding: 50px 0;
    height: 100vh;
}

.hero-section video{
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
    opacity: 1000;
}

.hero-section .content{
    margin-top: 310px;
    max-width: 430px;
    margin-bottom: 140px;
    color: #FFFFFF;
}

.hero-section .content .brand-name{
    font-size: 55px;
}

.hero-section .content h1{
    margin-bottom: 15px;
    font-weight: 700;
}

.hero-section .content p{
    font-size: 14px;
}

@media screen and (max-width: 912px) {
    .hero-section .content{
        margin-top: 880px;
    }
}

@media screen and (max-width: 820px) {
    .hero-section .content{
        margin-top: 700px;
    }
}

@media screen and (max-width: 768px) {
    .hero-section .content{
        margin-top: 550px;
    }
}

@media screen and (max-width: 540px) {
    .hero-section .content{
        margin-top: 450px;
    }
}

@media screen and (max-width: 394px) {
    .hero-section .content{
        margin-top: 350px;
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 376px) {
    .hero-section .content{
        margin-top: 200px;
    }
}